<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-text-field
          v-model="productPromo.id"
          label="Digital Ocean Id"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.ownerUserId"
          label="OwnerUserId"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.targetUserId"
          label="TargetUserId"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.expireTime"
          label="ExpireTime"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.expireCount"
          label="ExpireCount"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.currentCount"
          label="CurrentCount"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.chip"
          type="number"
          label="Chip"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.gold"
          label="Gold"
          type="number"
          :counter="max"
          required
        ></v-text-field>
        <v-text-field
          v-model="productPromo.vipPoint"
          label="VipPoint"
          type="number"
          :counter="max"
          required
        ></v-text-field>
      </v-flex>
      <v-btn color="success" @click="save">Kaydet</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import router from "@/router";
export default {
  data: () => ({
    productPromo: {},
    productPromos: [],
    alert: false,
    max: 120,
    messageCode: "MESSAGE01",
    deployScheduleTime: 5,
    deployStart: false,
    deploymentType: "Schedule",
    items: ["Quicky", "Schedule"],
    transferServer: null,
  }),

  methods: {
    async save() {
      this.productPromo.expireTime = parseInt(
        this.productPromo.expireTime || 0
      );
      this.productPromo.expireCount = parseInt(
        this.productPromo.expireCount || 0
      );
      this.productPromo.currentCount = parseInt(
        this.productPromo.currentCount || 0
      );
      this.productPromo.chip = parseInt(this.productPromo.chip || 0);
      this.productPromo.gold = parseInt(this.productPromo.gold || 0);
      this.productPromo.vipPoint = parseInt(this.productPromo.vipPoint || 0);
      await this.$store.dispatch(
        "productPromos/createOrUpdate",
        this.productPromo
      );
      router.push("/products");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {
    if (this.$route.params.id)
      await this.$store.dispatch("productPromos/get", this.$route.params.id);
    this.productPromo = this.$store.state.productPromos.detail;
  },
};
</script>
